@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('../fonts/gilroy-extrabold-webfont.woff2') format('woff2'),
       url('../fonts/gilroy-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('../fonts/gilroy-light-webfont.woff2') format('woff2'),
       url('../fonts/gilroy-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: $light-font-family; 
}

h1, h2, h3, h4, h5, h6 {
  font-family: $heavy-font-family;
}