// Clearfix
// https://css-tricks.com/snippets/css/clear-fix/
.group:after, .clearfix:after {
  content: "";
  display: table;
  clear: both;
}

// Visually hidden
// https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
.visually-hidden { 
  position: absolute !important;
  height: 1px; width: 1px; 
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}