body {
  &.thanks-page {
    &::before {
      @include breakpoint($portrait) {
        @include breakpoint($absolute-layout-breakpoint) {
          display: block;
        }
      }
      @include breakpoint($landscape) {
        @include breakpoint($absolute-layout-breakpoint) {
          display: block;
        }
      }
    }
  }
}

.thanks {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: relative;

  &__logo {
    height: auto;
    margin: 0 0 1rem 0;
    width: 10rem;
  }

  &__heading {
    font-size: 1.6rem;
    font-weight: bolder;
    letter-spacing: 1.2px;
    margin: 0 0 1rem 0;
    max-width: 16rem;
    text-align: center;
    text-transform: uppercase;
  }

  &__para {
    margin: 0 0 3rem 0;
    text-align: center;
  }

  &__link {
    margin: 0;
    text-align: center;
    text-decoration: none;
  }

  &__link-arrow {
    font-size: 0.7rem;
    transform: translateY(-1px);
    display: inline-block;
  }

  &__link-text {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}