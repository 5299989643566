// Colors
$color-black: #000;
$color-white: #fff;
$brand-primary: #1c3c5c !default;

// Font sizes
$base-font-size: 16px;
$base-font-color: $brand-primary;

// Max widths
$site-max-width: 1280px;

// Font families
$heavy-font-family: 'Gilroy-ExtraBold', Arial, sans-serif;
$light-font-family: 'Gilroy-Light', 'Helvetica Neue', Helvetica, Arial,
  sans-serif;

// Margins & Padding
$standard-margin: 1rem 0;
$standard-padding: 0.5rem 1rem;
