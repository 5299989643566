body {
  &.holding-page {
    background-color: #000;
    width: 100vw;
    height: 100vh;
    background-image: url('../img/holding-image.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &::before {
      display: none;
    }
  }
}